
#id640624da9b0ba7dbe6f18d40 {
  #ixok {
padding : 10px; 
min-height : 100px; 
__bg-type : color; 
background-image : linear-gradient(rgba(52, 58, 56, 1),rgba(52, 58, 56, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#ib79 {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#ikqg {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
} 
#ihi2 {
padding : 10px; 
min-height : 100px; 
width : 10%; 
display : flex; 
justify-content : center; 
align-items : center; 
align-self : center; 
} 
#if8si {
padding : 10px; 
min-height : 100px; 
width : 44.07%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
align-self : center; 
} 
#ih7cj {
color : black; 
max-width : auto; 
} 
#i4su3 {
padding : 10px; 
display : block; 
color : #fff; 
} 
#i5c9y {
padding : 10px; 
display : block; 
color : #fff; 
} 
#iw65y {
padding : 10px 11px 10px 10px; 
display : block; 
color : #fff; 
opacity : 1; 
text-align : left; 
} 
#iuugo {
padding : 10px; 
min-height : 100px; 
width : 20%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i2u8k {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
align-items : flex-end; 
justify-content : center; 
position : relative; 
} 
#i5re6 {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#ibhjv {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i5fiv {
color : black; 
} 
#isx9j {
color : black; 
bottom : auto; 
position : absolute; 
} 
#ivyke {
padding : 10px; 
min-height : 50px; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : linear-gradient(rgba(78, 203, 83, 1),rgba(78, 203, 83, 1)); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : #000; 
__bg-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
width : 100px; 
border-radius : 0 0 0 0; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#iq3do {
padding : 10px; 
min-height : 100px; 
} 
#in2ae {
padding : 10px; 
min-height : auto; 
display : flex; 
align-items : center; 
} 
#ip88d {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
} 
#i45wn {
padding : 10px 5px 10px 5px; 
min-height : 75px; 
__bg-type : color; 
background-image : linear-gradient(rgba(108, 183, 64, 1),rgba(108, 183, 64, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#ii4t8 {
padding : 10px; 
display : block; 
font-weight : 700; 
font-size : 45px; 
} 
#ipvdg {
padding : 10px; 
min-height : 100px; 
width : 44.59%; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-items : center; 
margin : 5px 5px 5px 5px; 
height : 324px; 
} 
#ihd4r {
color : black; 
} 
#ib79w {
padding : 10px; 
display : block; 
font-size : 20px; 
margin : 15px 0 0 0; 
} 
#i10xk {
padding : 10px; 
min-height : 100px; 
width : 100%; 
} 
#ine7z {
padding : 10px; 
display : block; 
text-align : center; 
} 
#i1uwi {
color : black; 
} 
#igujy {
padding : 10px; 
display : block; 
font-size : 20px; 
margin : 15px 0 0 0; 
} 
#i3o8v {
padding : 10px; 
display : block; 
text-align : center; 
} 
#izjpn {
padding : 10px; 
min-height : 100px; 
width : 100%; 
} 
#ibsfh {
padding : 10px; 
min-height : 100px; 
width : 51.72%; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-items : center; 
margin : 5px 5px 5px 5px; 
height : 350px; 
} 
#ieav8 {
padding : 0 10px 10px 10px; 
min-height : auto; 
__bg-type : color, img; 
background-image : linear-gradient(rgba(37, 40, 43, 0.54),rgba(37, 40, 43, 0.54)), url('https://assetsprojects.s3.amazonaws.com/404mwbilexaf2li.png'); 
background-repeat : repeat, no-repeat; 
background-position : left top, center center; 
background-attachment : scroll, scroll; 
background-size : auto, cover; 
position : relative; 
height : 520px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iykm3 {
padding : 50px 10px 50px 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#irqsj {
padding : 10px; 
min-height : 250px; 
width : 60%; 
__bg-type : color; 
background-image : linear-gradient(rgba(242, 242, 242, 0.2),rgba(242, 242, 242, 0.2)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-items : center; 
} 
#imoryu {
padding : 10px; 
display : block; 
color : #fff; 
font-weight : 600; 
font-size : 40px; 
} 
#ir611b {
padding : 10px; 
display : block; 
color : #fff; 
font-weight : 300; 
font-size : 19px; 
} 
#ifqmpe {
padding : 10px; 
min-height : 100px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#id68ra {
border : 1px solid rgba(78, 203, 83, 1); 
__bg-type : color; 
background-image : linear-gradient(transparent,transparent); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
color : rgba(78, 203, 83, 1); 
padding : 5px 10px 5px 10px; 
font-size : 16px; 
width : 210px; 
} 
#ifvqt7 {
font-size : medium; 
} 
#iz3vlr {
color : #fff; 
border : 1px solid rgba(78, 203, 83, 1); 
padding : 5px 10px 5px 10px; 
width : 180px; 
} 
#ir5u3o {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-items : center; 
} 
#it81hu {
padding : 10px; 
min-height : 100px; 
width : 50%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404ofuilgfqemrn.png'); 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : auto; 
} 
#ifk61d {
padding : 10px; 
min-height : 100px; 
width : 100%; 
display : flex; 
flex-direction : row; 
} 
#ifc09v {
padding : 10px 5px 10px 5px; 
min-height : 75px; 
__bg-type : color; 
background-image : linear-gradient(rgba(108, 183, 64, 1),rgba(108, 183, 64, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#ir9pbl {
padding : 10px; 
min-height : 100px; 
width : 90%; 
} 
#i1n1aa {
padding : 10px; 
display : block; 
font-size : 30px; 
font-weight : 700; 
} 
#ifql3q {
padding : 10px; 
display : block; 
font-size : 30px; 
width : 80%; 
} 
#imspvg {
padding : 10px 5px 10px 5px; 
min-height : 75px; 
__bg-type : color; 
background-image : linear-gradient(rgba(108, 183, 64, 1),rgba(108, 183, 64, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#im0b0l {
padding : 10px; 
display : block; 
font-size : 30px; 
font-weight : 700; 
} 
#i9sc1f {
padding : 10px; 
display : block; 
font-size : 30px; 
width : 80%; 
} 
#iu3bfq {
padding : 10px; 
min-height : 100px; 
width : 90%; 
} 
#icd57u {
padding : 10px; 
min-height : 100px; 
width : 100%; 
display : flex; 
flex-direction : row; 
} 
#idq7gj {
padding : 60px 10px 10px 10px; 
min-height : 100px; 
__bg-type : color; 
background-image : linear-gradient(rgba(52, 58, 56, 1),rgba(52, 58, 56, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
justify-content : center; 
} 
#i6nxf3 {
padding : 10px 10px 10px 50px; 
min-height : 100px; 
width : 30%; 
position : relative; 
} 
#iibeuk {
padding : 10px 10px 50px 10px; 
min-height : 100px; 
width : 70%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i0x9r4 {
padding : 10px; 
display : block; 
text-align : center; 
color : #fff; 
width : 60%; 
font-size : 24px; 
} 
#ig2ifr {
padding : 10px; 
min-height : 500px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#it7eos {
padding : 10px; 
display : block; 
font-size : 24px; 
text-align : center; 
} 
#in7dtt {
padding : 10px 10px 10px 10px; 
min-height : 225px; 
__bg-type : color; 
background-image : linear-gradient(rgba(52, 58, 56, 1),rgba(52, 58, 56, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
justify-content : center; 
align-items : stretch; 
} 
#i2oebe {
padding : 10px; 
min-height : 150px; 
display : flex; 
align-items : flex-end; 
} 
#immkzl {
padding : 10px; 
min-height : 225px; 
width : 35%; 
position : relative; 
display : flex; 
justify-content : center; 
align-items : flex-end; 
align-self : auto; 
height : 100%; 
} 
#imv0cf {
padding : 10px; 
min-height : 100px; 
width : 65%; 
display : flex; 
justify-content : center; 
align-items : center; 
position : relative; 
} 
#i8h54l {
padding : 10px; 
display : block; 
color : #fff; 
text-align : center; 
width : 60%; 
font-size : medium; 
} 
#imy4ii {
color : black; 
position : absolute; 
margin : 0 0 0 0; 
} 
#ircepv {
padding : 10px; 
min-height : 100px; 
width : 25%; 
display : flex; 
align-items : flex-end; 
} 
#iev7tp {
padding : 10px; 
display : block; 
font-size : 12px; 
} 
#iczgtf {
color : black; 
width : 15px; 
} 
#i8othv {
padding : 10px; 
min-height : auto; 
width : 100%; 
display : flex; 
justify-content : space-between; 
align-items : center; 
} 
#ictlkj {
color : black; 
width : 25px; 
} 
#ilzmk2 {
color : black; 
width : 25px; 
} 
#iih7tk {
padding : 10px; 
min-height : 100px; 
width : 50%; 
border : 5px solid rgba(112, 181, 70, 1); 
position : absolute; 
height : 375px; 
display : flex; 
justify-content : center; 
align-items : center; 
align-self : center; 
} 
@media only screen and (max-width: 992px) {#ihi2 {
width : 10%; 
}} 
@media only screen and (max-width: 992px) {#if8si {
width : 40%; 
}} 
@media only screen and (max-width: 992px) {#iuugo {
width : 35%; 
}} 
@media only screen and (max-width: 992px) {#ipvdg {
width : 40%; 
}} 
@media only screen and (max-width: 992px) {#ibsfh {
width : 40%; 
}} 
@media only screen and (max-width: 992px) {#ip88d {
align-self : auto; 
}} 
@media only screen and (max-width: 992px) {#imoryu {
font-size : 24px; 
text-align : center; 
}} 
@media only screen and (max-width: 992px) {#ir611b {
font-size : 14px; 
}} 
@media only screen and (max-width: 992px) {#it81hu {
width : 30%; 
display : none; 
}} 
@media only screen and (max-width: 992px) {#ir5u3o {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iykm3 {
background-position : right center; 
display : block; 
}} 
@media only screen and (max-width: 992px) {#i6nxf3 {
display : flex; 
align-items : flex-end; 
justify-content : center; 
padding : 10px 10px 10px 10px; 
width : 363px; 
}} 
@media only screen and (max-width: 992px) {#i0x9r4 {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#idq7gj {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#iih7tk {
width : 70%; 
}} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#izxrx4 {
width : 80%; 
} 
#ihvlrh {
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iw034b {
color : black; 
} 
#ilv6al {
padding : 10px; 
display : block; 
font-size : 20px; 
margin : 15px 0 0 0; 
} 
#iue294 {
padding : 10px; 
display : block; 
text-align : center; 
} 
#i6013h {
padding : 10px; 
min-height : 100px; 
width : 100%; 
} 
#i9bv1i {
padding : 10px; 
min-height : 100px; 
width : 49.28%; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-items : center; 
margin : 5px 5px 5px 5px; 
height : 333px; 
} 
@media only screen and (max-width: 992px) {#i9bv1i {
width : 40%; 
}} 
#iw08nb {
color : black; 
} 
#iezfll {
padding : 10px; 
display : block; 
font-size : 20px; 
margin : 15px 0 0 0; 
} 
#i4rhsy {
padding : 10px; 
display : block; 
text-align : center; 
} 
#iw65sn {
padding : 10px; 
min-height : 100px; 
width : 100%; 
} 
#iweccj {
padding : 10px; 
min-height : 100px; 
width : 52.74%; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-items : center; 
margin : 5px 5px 5px 5px; 
height : 368px; 
} 
@media only screen and (max-width: 992px) {#iweccj {
width : 40%; 
}} 
#in9wpv {
display : flex; 
justify-content : center; 
align-items : center; 
} 
@media only screen and (max-width: 992px) {#izxrx4 {
flex-direction : column; 
}} 
@media only screen and (max-width: 992px) {#ihvlrh {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#in9wpv {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#in9wpv {
flex-direction : column; 
}} 
@media only screen and (max-width: 480px) {#izxrx4 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ihvlrh {
flex-direction : column; 
}} 
@media only screen and (max-width: 480px) {#ipvdg {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ibsfh {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i9bv1i {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iweccj {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#irqsj {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#it81hu {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ir5u3o {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i0x9r4 {
font-size : 15px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iibeuk {
padding : 10px 10px 10px 10px; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ircepv {
width : 40%; 
}} 
@media only screen and (max-width: 480px) {#ircepv {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iih7tk {
height : 325px; 
width : 90%; 
}} 
@media only screen and (max-width: 480px) {#i8h54l {
font-size : 12px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#it7eos {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#ii4t8 {
font-size : 32px; 
}} 
@media only screen and (max-width: 480px) {#if8si {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ihi2 {
width : 42.41%; 
}} 
@media only screen and (max-width: 480px) {#iuugo {
width : 59.31%; 
}} 
@media only screen and (max-width: 480px) {#i1n1aa {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#ifql3q {
font-size : 11px; 
}} 
@media only screen and (max-width: 480px) {#im0b0l {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#i9sc1f {
font-size : 8px; 
}} 
@media only screen and (max-width: 480px) {#iykm3 {
background-size : contain; 
}} 
@media only screen and (max-width: 480px) {#i2u8k {
width : 30%; 
display : none; 
}} 
@media only screen and (max-width: 480px) {#i5re6 {
width : 70%; 
}} 
#imj4l9 {
font-size : 1rem; 
} 
#icygrk {
font-size : 1rem; 
} 
#ijzwch {
font-size : 1rem; 
} 
#iofsip {
font-size : 1rem; 
} 
#i8sotx {
padding : 10px; 
min-height : 100px; 
} 
#iuk43f {
font-size : 1rem; 
} 
#iy2llw {
font-family : Calibri, sans-serif; 
font-size : 18pt; 
} 
#icya09 {
font-size : 18pt; 
} 
#iwa7km {
color : black; 
position : absolute; 
height : 302px; 
width : 72.91%; 
} 
@media only screen and (max-width: 992px) {#iwa7km {
position : relative; 
bottom : 0; 
top : 0; 
height : 182px; 
}} 
@media only screen and (max-width: 480px) {#iwa7km {
top : 0; 
}} 
.link {
color : #ffffff; 
} 
@media only screen and (max-width: 992px) {#iw65y {
font-size : medium; 
padding : 10px 5px 10px 5px; 
}} 
@media only screen and (max-width: 992px) {#i4su3 {
padding : 10px 5px 10px 5px; 
}} 
@media only screen and (max-width: 992px) {#i5c9y {
padding : 10px 5px 10px 5px; 
}} 
@media only screen and (max-width: 480px) {#i6nxf3 {
display : flex; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#idq7gj {
display : block; 
}} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#iz9w5a {
display : block; 
} 
#i3ox1c {
display : block; 
} 
#ikrgh9 {
display : block; 
} 
#izjo5n {
display : block; 
} 

  @media only screen and (max-width: 992px) {#if8si {
width : 384.5859375px; 
}} 

  
}
  