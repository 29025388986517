
#id65b96ac2b0184704f908ea08 {
  #iu93 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
} 
#ik3f {
min-height : 100px; 
__background-type : image; 
background-repeat : repeat; 
background-position : left bottom; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilsmcx92d.png'); 
} 
#i71w {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
} 
#i9t9 {
padding : 10px; 
min-height : 50px; 
width : 200px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iv25 {
color : black; 
width : 100px; 
} 
#ipm36 {
padding : 10px; 
min-height : 100px; 
width : 20%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#i81dl {
padding : 10px; 
display : block; 
font-size : 12px; 
font-weight : 500; 
font-family : Helvetica, sans-serif; 
} 
#ijrwk {
color : black; 
} 
#i5n3w {
color : black; 
min-height : 10auto; 
width : 24px; 
} 
#iprxd {
color : black; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#intwl {
min-height : 100px; 
padding : 10px; 
} 
#ioc6c {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i7uyk {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ij9dv {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
font-size : 20px; 
text-align : justify; 
} 
#ivxnv {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
font-size : 20px; 
} 
#if1sc {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#idg8i {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
.title-object {
min-height : 100px; 
display : flex; 
justify-content : center; 
align-items : center; 
font-family : Tahoma, sans-serif; 
font-size : 3rem; 
border : 1px solid #343a38; 
border-radius : 50px 50px 50px 50px; 
} 
#iicz1 {
padding : 10px; 
min-height : 100px; 
} 
#ipyfy {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
justify-content : center; 
align-items : flex-start; 
} 
.info-container {
margin : 50px 0 0 0; 
display : flex; 
flex-wrap : wrap; 
} 
#icusm {
padding : 10px; 
min-height : 100px; 
width : 50%; 
text-align : center; 
} 
.imgDynamics {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#ib6l5 {
padding : 5px; 
width : 100%; 
height : auto; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#idmq5 {
padding : 10px; 
display : block; 
font-weight : 700; 
font-size : 25px; 
} 
.tarjeta-info {
width : 480px; 
box-shadow : 0 0 5px 0 black ; 
border-radius : 10px 10px 10px 10px; 
margin : 25px 0px 25px 0px; 
text-align : center; 
} 
#i9nzg {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
} 
#ie8e8 {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
} 
#iiam57 {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
} 
#iqmamv {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
} 
#iccer {
padding : 10px; 
min-height : 100px; 
width : 75%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#343a38 0%, #343a38 100%); 
border-radius : 15px 15px 15px 15px; 
} 
#ilea1 {
padding : 10px; 
min-height : 129px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f0f0f0 0%, #f0f0f0 100%); 
} 
#ie67s {
padding : 10px 10px 10px 0px; 
display : flex; 
align-items : center; 
font-size : 38px; 
} 
#i2rtp {
color : black; 
} 
#ihpxa {
min-height : 3px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#6cb740 0%, #6cb740 100%); 
width : 166px; 
} 
#i4w1f {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
margin : 25px 0px 25px 0px; 
} 
#imx2n {
padding : 10px; 
min-height : 100px; 
} 
.card {
width : 308px; 
border-radius : 10px 10px 10px 10px; 
border : 1px solid #6cb740; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f5f5f5 0%, #f5f5f5 100%); 
} 
#ibfwr {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#iq68p {
color : black; 
} 
#i1839 {
color : black; 
} 
#iehml {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#i34gxp {
padding : 10px; 
display : block; 
font-weight : 700; 
font-size : 25px; 
} 
#ifzb45 {
padding : 10px; 
min-height : 100px; 
} 
#i407jf {
padding : 10px 10px 10px 10px; 
min-height : 100px; 
border-radius : 10px 10px 10px 10px; 
border : 1px solid #6cb740; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f5f5f5 0%, #f5f5f5 100%); 
box-shadow : 0px 4px 18px 0px #dad9d9 ; 
margin : 0px 5% 0px 5%; 
} 
#ipgxh7 {
padding : 10px; 
min-height : 100px; 
} 
#i8y6nb {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
} 
#imrxie {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#c9c9c9 0%, #c9c9c9 100%); 
height : 1px; 
} 
#ifki22 {
padding : 10px; 
width : 50%; 
display : flex; 
justify-content : flex-start; 
align-items : center; 
} 
#iy6dg4 {
padding : 10px; 
width : 50%; 
text-align : right; 
} 
#it9lur {
color : black; 
} 
#isw1b6 {
color : black; 
} 
#i2mu8h {
padding : 10px; 
width : 50%; 
display : flex; 
justify-content : flex-start; 
align-items : center; 
} 
#i1lrxx {
padding : 10px; 
width : 50%; 
text-align : right; 
} 
#ilpikj {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
} 
#i28g5l {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#c9c9c9 0%, #c9c9c9 100%); 
height : 1px; 
} 
#ihsowt {
padding : 10px; 
min-height : 100px; 
} 
#i0dhkj {
color : black; 
width : 49px; 
min-height : 55auto; 
} 
#i8dcci {
padding : 10px; 
width : 50%; 
display : flex; 
justify-content : flex-start; 
align-items : center; 
} 
#i6linh {
padding : 10px; 
width : 50%; 
text-align : right; 
} 
#i954jg {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
} 
#i09z9i {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#c9c9c9 0%, #c9c9c9 100%); 
height : 1px; 
} 
#in1j0t {
padding : 10px; 
min-height : 100px; 
} 
#ikm15g {
color : black; 
} 
#id5mtg {
padding : 10px; 
width : 50%; 
display : flex; 
justify-content : flex-start; 
align-items : center; 
} 
#impe8a {
padding : 10px; 
width : 50%; 
text-align : right; 
} 
#iluzgb {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
} 
#i6d9g4 {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#c9c9c9 0%, #c9c9c9 100%); 
height : 1px; 
} 
#iov06g {
padding : 10px; 
min-height : 100px; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#iq8xwj {
display : block; 
} 
#ii52an {
display : block; 
} 
#il4fz3 {
display : block; 
} 
#iftpdx {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i852ug {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
font-size : 20px; 
text-align : center; 
} 

  @media only screen and (max-width: 992px) {#ipm36 {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#iccer {
display : flex; 
}} 
@media only screen and (max-width: 992px) {#icusm {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {.info-container {
display : block; 
margin : 10px 0px 0px 0px; 
}} 
@media only screen and (max-width: 992px) {#ipyfy {
width : 100%; 
}} 

  @media only screen and (max-width: 480px) {#ipm36 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ipyfy {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#icusm {
width : 100%; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {.info-container {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ij9dv {
text-align : center; 
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#iccer {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.title-object {
font-size : 2rem; 
}} 
@media only screen and (max-width: 480px) {#ie67s {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#idmq5 {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#ivxnv {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {.card {
margin : 25px 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#i8y6nb {
display : block; 
}} 
@media only screen and (max-width: 480px) {#ifki22 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iy6dg4 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iluzgb {
display : block; 
}} 
@media only screen and (max-width: 480px) {#id5mtg {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#impe8a {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i954jg {
display : block; 
}} 
@media only screen and (max-width: 480px) {#i8dcci {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i6linh {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ilpikj {
display : block; 
}} 
@media only screen and (max-width: 480px) {#i2mu8h {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i1lrxx {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i407jf {
margin : 0px 0% 0px 0%; 
}} 
@media only screen and (max-width: 480px) {#ilea1 {
min-height : 35px; 
}} 

}
  