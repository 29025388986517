
#id65bac8eab0184704f908f0cc {
  #iu93 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
} 
#ik3f {
min-height : 100px; 
} 
#i71w {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#fafafa 0%, #fafafa 100%); 
} 
#i9t9 {
padding : 10px; 
min-height : 50px; 
width : 200px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iv25 {
color : black; 
width : 100px; 
} 
#ipm36 {
padding : 10px; 
min-height : 100px; 
width : 20%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#i81dl {
padding : 10px; 
display : block; 
font-size : 12px; 
font-weight : 500; 
font-family : Helvetica, sans-serif; 
} 
#ijrwk {
color : black; 
} 
#i5n3w {
color : black; 
min-height : 10px; 
width : 24px; 
} 
#iprxd {
color : black; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#io19w {
padding : 10px 10% 10px 10%; 
min-height : 129px; 
font-size : 38px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f0f0f0 0%, #f0f0f0 100%); 
} 
.title-object {
min-height : 100px; 
display : flex; 
justify-content : center; 
align-items : center; 
font-family : Tahoma, sans-serif; 
font-size : 3rem; 
} 
.info-container {
margin : 50px 0 0 0; 
display : flex; 
flex-wrap : wrap; 
} 
.imgDynamics {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#ib6l5 {
padding : 5px; 
width : 100%; 
height : auto; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#itqo2 {
color : black; 
width : 22px; 
} 
#ijp4l {
padding : 10px; 
display : block; 
font-weight : 700; 
} 
#idmq5 {
padding : 10px; 
display : block; 
font-weight : 700; 
margin : 0 0 0 15px; 
} 
.tarjeta-info {
width : 450px; 
box-shadow : 0 0 5px 0 black ; 
border-radius : 10px 10px 10px 10px; 
margin : 25px 0px 25px 0px; 
text-align : center; 
} 
#i9nzg {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
} 
#ie8e8 {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
} 
#iiam57 {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
} 
#iqmamv {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
} 
#iccer {
padding : 20px 20px 20px 20px; 
min-height : 100px; 
width : 80%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#343a38 0%, #343a38 100%); 
border-radius : 15px 15px 15px 15px; 
margin : -70px 0px 0px 0px; 
} 
#idnx2 {
min-height : 100px; 
} 
#i7add {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i7221 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
text-align : right; 
} 
#iet0w {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iuye2 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iecd9e {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ietxyk {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ilysj6 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iog8hl {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i2rkvv {
color : black; 
width : 40px; 
} 
#izgbk {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
} 
#ik7jk {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
} 
#iujt7v {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#imjdv {
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#fafafa 0%, #fafafa 100%); 
} 
#idr7h {
padding : 0px 10px 10px 10%; 
min-height : 100px; 
width : 50%; 
} 
#ijjmdi {
padding : 10px 10px 10px 20px; 
min-height : 100px; 
width : 50%; 
display : flex; 
justify-content : center; 
align-items : flex-start; 
} 
#inazy8 {
padding : 0px 10px 10px 10px; 
min-height : 100px; 
} 
#ibynqd {
min-height : 3px; 
width : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#6cb740 0%, #6cb740 100%); 
} 
#ixal4t {
padding : 10px; 
min-height : 100px; 
} 
#i4umna {
padding : 10px; 
display : block; 
font-size : 18px; 
} 
#i8hqv4 {
min-height : 2px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e5e5e5 0%, #e5e5e5 100%); 
margin : 15px 0px 15px 0px; 
} 
#iq7y6x {
padding : 10px; 
min-height : 100px; 
border : 1px solid #343a38; 
border-radius : 10px 10px 10px 10px; 
display : flex; 
flex-wrap : wrap; 
font-size : 18px; 
margin : 15px 0px 15px 0px; 
} 
#iwgw87 {
padding : 10px; 
width : 50%; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
} 
#iz7swt {
padding : 10px; 
width : 50%; 
} 
#it7cgf {
color : black; 
width : 40px; 
} 
#is2f19 {
padding : 10px; 
width : 50%; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
} 
#iy0h34 {
padding : 10px; 
width : 50%; 
text-align : right; 
} 
#i32ivv {
padding : 10px; 
min-height : 100px; 
border : 1px solid #343a38; 
border-radius : 10px 10px 10px 10px; 
display : flex; 
flex-wrap : wrap; 
font-size : 18px; 
margin : 15px 0px 15px 0px; 
} 
#ieu15h {
color : black; 
width : 40px; 
} 
#ij1weq {
padding : 10px; 
width : 50%; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
} 
#ihc2uq {
padding : 10px; 
width : 50%; 
} 
#i37kur {
padding : 10px; 
min-height : 100px; 
border : 1px solid #343a38; 
border-radius : 10px 10px 10px 10px; 
display : flex; 
flex-wrap : wrap; 
font-size : 18px; 
margin : 15px 0px 15px 0px; 
} 
#i6sdgg {
color : black; 
width : 40px; 
} 
#isgtre {
padding : 10px; 
width : 50%; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
} 
#im4n82 {
padding : 10px; 
width : 50%; 
} 
#iaqqrh {
padding : 10px; 
min-height : 100px; 
border : 1px solid #343a38; 
border-radius : 10px 10px 10px 10px; 
display : flex; 
flex-wrap : wrap; 
font-size : 18px; 
margin : 15px 0px 15px 0px; 
} 
#ib12uf {
padding : 10px; 
width : 50%; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
} 
#iz71jx {
padding : 10px; 
width : 50%; 
} 
#ii4hqk {
padding : 10px; 
min-height : 100px; 
border : 1px solid #343a38; 
border-radius : 10px 10px 10px 10px; 
display : flex; 
flex-wrap : wrap; 
font-size : 18px; 
margin : 15px 0px 15px 0px; 
} 
.label-info {
margin : 0 0 0 15px; 
} 
.info-right {
text-align : right; 
} 
#i9gs7w {
color : black; 
width : 40px; 
} 
#i2hras {
padding : 10px; 
width : 50%; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
} 
#iabnw2 {
padding : 10px; 
width : 50%; 
text-align : right; 
} 
#ildcbc {
padding : 10px; 
min-height : 100px; 
border : 1px solid #343a38; 
border-radius : 10px 10px 10px 10px; 
display : flex; 
flex-wrap : wrap; 
font-size : 18px; 
margin : 15px 0px 15px 0px; 
} 
#ifesc1 {
color : black; 
width : 40px; 
} 
#izezfh {
padding : 10px; 
width : 50%; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
} 
#iehi3l {
padding : 10px; 
width : 50%; 
text-align : right; 
} 
#ikgjog {
padding : 10px; 
min-height : 100px; 
border : 1px solid #343a38; 
border-radius : 10px 10px 10px 10px; 
display : flex; 
flex-wrap : wrap; 
font-size : 18px; 
margin : 15px 0px 15px 0px; 
} 
#itv2s9 {
color : black; 
width : 40px; 
min-height : 10auto; 
} 
#ia1wjh {
padding : 10px; 
width : 50%; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
} 
#i8wog4 {
padding : 10px; 
width : 50%; 
text-align : right; 
} 
#iv35fm {
padding : 10px; 
min-height : 100px; 
border : 1px solid #343a38; 
border-radius : 10px 10px 10px 10px; 
display : flex; 
flex-wrap : wrap; 
font-size : 18px; 
margin : 15px 0px 15px 0px; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#is6nay {
display : block; 
} 
#i85cif {
display : block; 
} 
#i3tvb9 {
display : block; 
} 
#if42iu {
font-size : 13.5pt; 
line-height : 115%; 
font-family : Arial, sans-serif; 
background-image : initial; 
background-position : initial; 
background-size : initial; 
background-repeat : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
} 

  @media only screen and (max-width: 992px) {#ipm36 {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#iccer {
display : flex; 
margin : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 992px) {#idr7h {
width : 100%; 
padding : 0px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#imjdv {
flex-direction : column-reverse; 
justify-content : space-around; 
align-items : center; 
padding : 0px 5% 0px 5%; 
}} 
@media only screen and (max-width: 992px) {#ijjmdi {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#inazy8 {
display : none; 
}} 
@media only screen and (max-width: 992px) {#i4umna {
margin : 25px 0 0 0; 
}} 

  @media only screen and (max-width: 480px) {#ipm36 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iccer {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.info-container {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {.title-object {
font-size : 2rem; 
}} 
@media only screen and (max-width: 480px) {#i4umna {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#idr7h {
padding : 0px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#iq7y6x {
font-size : 16px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#ii4hqk {
font-size : 16px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#iwgw87 {
width : 100%; 
justify-content : space-between; 
}} 
@media only screen and (max-width: 480px) {#i2rkvv {
width : 22px; 
}} 
@media only screen and (max-width: 480px) {#i6sdgg {
width : 22px; 
}} 
@media only screen and (max-width: 480px) {#ieu15h {
width : 22px; 
}} 
@media only screen and (max-width: 480px) {#it7cgf {
width : 22px; 
}} 
@media only screen and (max-width: 480px) {#i9gs7w {
width : 22px; 
}} 
@media only screen and (max-width: 480px) {#itv2s9 {
width : 22px; 
}} 
@media only screen and (max-width: 480px) {#ifesc1 {
width : 22px; 
}} 
@media only screen and (max-width: 480px) {#iaqqrh {
font-size : 16px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#iz7swt {
width : 100%; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#i7221 {
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#ib12uf {
width : 100%; 
justify-content : space-between; 
}} 
@media only screen and (max-width: 480px) {#iz71jx {
width : 100%; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {.info-right {
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#isgtre {
width : 100%; 
justify-content : space-between; 
}} 
@media only screen and (max-width: 480px) {#im4n82 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i37kur {
display : block; 
}} 
@media only screen and (max-width: 480px) {#ij1weq {
width : 100%; 
justify-content : space-between; 
}} 
@media only screen and (max-width: 480px) {#ihc2uq {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i32ivv {
display : block; 
}} 
@media only screen and (max-width: 480px) {#is2f19 {
width : 100%; 
justify-content : space-between; 
}} 
@media only screen and (max-width: 480px) {#iy0h34 {
width : 100%; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#ildcbc {
display : block; 
}} 
@media only screen and (max-width: 480px) {#i2hras {
width : 100%; 
justify-content : space-between; 
}} 
@media only screen and (max-width: 480px) {#iabnw2 {
width : 100%; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#iv35fm {
display : block; 
}} 
@media only screen and (max-width: 480px) {#ia1wjh {
width : 100%; 
justify-content : space-between; 
}} 
@media only screen and (max-width: 480px) {#i8wog4 {
width : 100%; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#ikgjog {
display : block; 
}} 
@media only screen and (max-width: 480px) {#izezfh {
justify-content : space-between; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iehi3l {
width : 100%; 
text-align : center; 
}} 

}
  